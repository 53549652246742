import { CategoryScale, Chart, LinearScale, LineController, LineElement, PointElement } from 'chart.js';

// Register line controller.
Chart.register(LineController, CategoryScale, LinearScale, PointElement, LineElement);
const chartElements: { [key: string]: Chart<'line', (string | number)[], string> } = {};

/**
 * Create a line chart.
 *
 * @param teamId The team for which to create the chart.
 * @param dataset The dataset to create it with.
 */
export const createGraph = (teamId: string, dataset: { [key: number]: string; name?: string; totalPower?: number }) => {
    const parent = document.querySelector(`[data-team-id="${teamId}"]`)!;
    const graph = parent.querySelector('.graph') as HTMLCanvasElement;
    delete dataset.name;
    delete dataset.totalPower;
    // Create chart.js graph.
    let entries = Object.keys(dataset);
    if (entries.length > 10) {
        entries = entries.sort().slice(-10);
    }
    const labels = entries.map((unixTimestamp) => new Date(Number(unixTimestamp)).toTimeString().split(' ')[0]);
    const chart = new Chart(graph, {
        type: 'line',
        data: {
            labels: labels,
            datasets: [
                {
                    label: 'Power Output',
                    data: Object.values(dataset),
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                },
            ],
        },
    });
    chartElements[teamId] = chart;
};

/**
 * Deletes graph with id provided.
 *
 * @param teamId The item to delete.
 */
export const deleteGraph = (teamId: string) => {
    chartElements[teamId].destroy();
    delete chartElements[teamId];
};

/**
 * Updates the specified graph.
 *
 * @param teamId The team to update.
 * @param dataset The new dataset.
 */
export const changeGraph = (teamId: string, dataset: { [key: number]: string; name?: string; totalPower?: number }) => {
    delete dataset.name;
    delete dataset.totalPower;
    let entries = Object.keys(dataset);
    if (entries.length > 10) {
        entries = entries.sort().slice(-10);
    }
    const labels = entries.map((unixTimestamp) => new Date(Number(unixTimestamp)).toTimeString().split(' ')[0]);
    chartElements[teamId].data = {
        labels: labels,
        datasets: [
            {
                label: 'Power Output',
                data: Object.values(dataset),
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
        ],
    };
    chartElements[teamId].update();
};
